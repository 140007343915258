import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";
import Header from './Header';
import Footer from './Footer';
import AccentBox from '../../Components/AccentBox';
import { useSearchParams } from "react-router-dom";

function Price(){

    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const styledLink=(url,label)=>{
        return(<Link href={url} target="_blank" backgroundColor="#4771DF" borderColor="#4771DF" border={3} sx={{
            color:"white",
            textDecoration:"none",
            "&:hover":{
                backgroundColor:"white",
                color:"#4771DF"
            }
            }} fontWeight="bold" textAlign="center" mt={2} p={2} width={200} borderRadius={10}>
            <Box position="relative" sx={{
                "&::after":{
                    content:'"〉"',
                    position:"absolute",
                    right:"0",
                }
            }}>{label===void 0 ? "詳しく見る":label}</Box>
        </Link>)
    }

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"30px", 
            height:"30px", 
            minWidth:"30px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png) no-repeat",backgroundSize:"contain"
        }
    }

    const before_checkMark_blue2={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.png) no-repeat",backgroundSize:"contain"
        }
    }

    const customersList =[
        {
            id:"nicoichi",
            accountName:"株式会社にこいち",
            imageName:"TOP_casestudy1",
            industry:"広告業",
            employee:"7名",
            title:<>従業員にとって<br></br>働きやすい環境がつくれた</>,
            detail:"助成金コーディネートを導入して従業員の働きやすい環境づくりができ、会社の利益にもなりました。まずは従業員が働きやすい環境をしっかりとつくり、その結果、助成金が受給できるというサービスで本当の意味で会社の成長を応援してくれるサービスです。"
        },
        {
            id:"criacao",
            accountName:"株式会社Criacao",
            imageName:"TOP_casestudy2",
            industry:"サービス業",
            employee:"31名",
            title:<>安心して採用ができ<br></br>従業員が2倍に</>,
            detail:"自社の思い描く労働環境を実現するための整備がしっかりできました。労働基準法を遵守する形で就業規則、雇用契約書、制度導入のための協定書、労使協定を帳簿などに落とし込みができ、採用担当が採用に専念できる環境と働く社員とそのご家族まで安心できる環境が整いました。"
        },
        {
            id:"overcome-music",
            accountName:"株式会社OVERCOME MUSIC",
            imageName:"TOP_casestudy3",
            industry:"情報通信業",
            employee:"3名",
            title:<>社長が何役も兼任している企業に<br></br>オススメ</>,
            detail:"小規模の会社や個人事業主から法人成りしたばかりの会社さんには経営者が本業に集中できることが、会社の業績を上げるために重要です。助成金コーディネートは手間が少なく、自社の労務周りの整備から助成金受給までしっかりと行われるかつ経営リスク回避、社員に安心して働いてもらえる環境まで整えられます。"
        },
        
    ]

    return (
        <>
            <Header/>

            <Box component="section" backgroundColor="#D1EAFE" pt={30} pb={20}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">助成金コーディネートの料金</Box>
                </Box>
            </Box>

            <Box component="section" display={{xs:"none",md:"block"}} backgroundColor="#EBEBEB" pt={10} pb={10}>
                <Box className='section-content' >

                    <Stack flexDirection={"row"} gap={{xs:1,md:2}} justifyContent="center" alignItems="center">
                        <Stack flex={{xs:1,md:2}} flexDirection="row" justifyContent="center">
                            <Box p="8px 0" flex={1} backgroundColor="#4771DF" color="white" textAlign="center" sx={{clipPath:"polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%)"}} fontSize={{xs:18,md:20}} fontWeight="bold">無料</Box>
                        </Stack>
                        <Stack width={{xs:60,md:100}}>
                            <Stack width={{xs:60,md:100}} height={{xs:60,md:100}} fontWeight="bold" borderRadius={100} backgroundColor="#4771DF" color="white" justifyContent="center" alignItems="center">
                                <Box fontSize={{xs:18,md:20}}>お申込</Box>
                                <Box fontSize={{xs:11,md:15}}>(任意)</Box>
                            </Stack>
                        </Stack>
                        <Stack flex={1} flexDirection="row" justifyContent="center" position="relative">
                            <Box position="absolute" top={-35} textAlign="center" fontSize={{xs:16,md:20}} fontWeight="bold">
                                <Box display="inline-block" backgroundColor="black" width={{xs:15,md:30}} height="1px" sx={{transform:" translateY(-5px) rotate(45deg)"}}/>
                                <Box display="inline-block" >プロにお任せ</Box>
                                <Box display="inline-block" backgroundColor="black" width={{xs:15,md:30}} height="1px" sx={{transform:" translateY(-5px) rotate(-45deg)"}}/>
                            </Box>
                            <Box p="8px 0" flex={1} backgroundColor="#4771DF" color="white" textAlign="center" sx={{clipPath:"polygon(0 0, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, 0 100%)"}} fontSize={{xs:18,md:20}} fontWeight="bold">月額 1.6万円～</Box>
                        </Stack>
                    </Stack>
                    <Stack flexDirection={"row"} gap={{xs:1,md:2}} height={10}>
                        <Stack flex={{xs:1,md:2}}/>
                        <Stack width={{xs:60,md:100}}>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="10px" height="10px" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={{xs:-8,md:-10}}></Box>
                            </Stack>
                        </Stack>
                        <Stack flex={1}/>
                    </Stack>
                    <Stack flexDirection={"row"} gap={{xs:1,md:2}}>
                        <Stack flex={{xs:1,md:2}}>
                            
                            <Stack flexDirection={{xs:"column",md:"row"}} width="100%" gap={2}>
                                <Stack p={{xs:1,md:"32px 16px"}} backgroundColor="white" flex={1} borderRadius={2} gap={2}>
                                    <Box flex={1} fontSize={{xs:18,md:25}} textAlign="center" fontWeight="bold">
                                        <Box>無料で</Box>
                                        <Box>助成金診断</Box>
                                    </Box>
                                    <ConversionButtonA p={{xs:"10px 5px",md:"10px 30px"}} display="block"textAlign="center" fontSize={{xs:16,md:20}} >助成金診断<Box component="span" margin="0 4px" fontSize={{xs:11,md:15}}>する</Box></ConversionButtonA>
                                </Stack>
                                <Stack p={{xs:1,md:"32px 16px"}} backgroundColor="white" flex={1} borderRadius={2} gap={2}>
                                    <Box flex={1} fontSize={{xs:18,md:25}} textAlign="center" fontWeight="bold">
                                        <Box>助成金</Box>
                                        <Box>個別無料コンサル</Box>
                                    </Box>
                                    <ConversionButtonB p={{xs:"10px 5px",md:"10px 30px"}} display="block" textAlign="center" fontSize={{xs:16,md:20}} >プロに相談<Box component="span" margin="0 4px"  fontSize={{xs:11,md:15}}>する</Box></ConversionButtonB>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack width={{xs:60,md:100}} justifyContent="center" alignItems="center" >
                            <Box display="inline-block" width="1px" height="95%" borderLeft="3px dotted #4771DF"/>
                        </Stack>
                        <Stack flex={1}>
                            <Stack p={{xs:1,md:"32px 16px"}} backgroundColor="white" borderRadius={2} gap={2}>
                                <Box flex={1} fontSize={{xs:18,md:25}} textAlign="center" fontWeight="bold">
                                    <Box>貴社専属チームが</Box>
                                    <Box>助成金申請サポート</Box>
                                </Box>
                                <Box>
                                    <Link display="block" href={"/flow"+queryString} color="#4771DF" borderColor="#4771DF" backgroundColor="white" target="_blank"  border={3} fontWeight="bold" textAlign="center" p={{xs:"10px 5px",md:"10px 30px"}} fontSize={{xs:16, md:20}} width="100%" borderRadius={100} sx={{
                                    textDecoration:"none",
                                    "&:hover":{
                                        backgroundColor:"#4771DF",
                                        color:"white",
                                    }
                                    }}>サービス内容<Box component="span" margin="0 4px"  fontSize={{xs:11,md:15}}>を</Box>知る</Link>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Box>

            <Box component="section" display={{xs:"block",md:"none"}} backgroundColor="#EBEBEB" pt={10} pb={10}>
                <Box className='section-content' >
                    
                    <Stack flexDirection="row" gap={1}>
                        
                        <Stack alignItems="center" flexBasis={80} >
                            <Box flex={1} p={1} flexDirection="row"  justifyContent="center" alignItems="center" backgroundColor="#4771DF" color="white" textAlign="center" sx={{writingMode:"vertical-rl", clipPath:"polygon(0 0, 100% 0,100% calc(100% - 15px), 50% 100%, 0 calc(100% - 15px))"}} fontSize={{xs:18,md:20}} fontWeight="bold">無料</Box>
                        </Stack>
                        

                        <Stack flex={{xs:1,md:2}}>
                            
                            <Stack flexDirection={{xs:"column",md:"row"}} width="100%" gap={2}>
                                <Stack p={2} backgroundColor="white" flex={1} borderRadius={2} gap={2}>
                                    <Box flex={1} fontSize={{xs:18,md:30}} textAlign="center" fontWeight="bold">
                                        <Box>無料で</Box>
                                        <Box>助成金診断</Box>
                                    </Box>
                                    <ConversionButtonA p={{xs:"10px 5px",md:"10px 30px"}} display="block"textAlign="center" fontSize={{xs:16,md:20}} >助成金診断<Box component="span" margin="0 4px" fontSize={{xs:11,md:15}}>する</Box></ConversionButtonA>
                                </Stack>
                                <Stack p={2} backgroundColor="white" flex={1} borderRadius={2} gap={2}>
                                    <Box flex={1} fontSize={{xs:18,md:30}} textAlign="center" fontWeight="bold">
                                        <Box>助成金</Box>
                                        <Box>個別無料コンサル</Box>
                                    </Box>
                                    <ConversionButtonB p={{xs:"10px 5px",md:"10px 30px"}} display="block" textAlign="center" fontSize={{xs:16,md:20}} >プロに相談<Box component="span" margin="0 4px"  fontSize={{xs:11,md:15}}>する</Box></ConversionButtonB>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    
                    <Stack flexDirection={"row"} mt={2} gap={1} alignItems="center">
                        <Stack width={80}>
                            <Stack flexDirection="row" width={80} height={80} fontWeight="bold" borderRadius={100} backgroundColor="#4771DF" color="white" justifyContent="center" alignItems="center">
                                <Box fontSize={18} sx={{writingMode:"vertical-rl"}}>お申込</Box>
                                <Box fontSize={11} sx={{writingMode:"vertical-rl"}}>(任意)</Box>
                            </Stack>
                        </Stack>
                        
                        <Stack justifyContent="center" alignItems="center" position="relative" ml="-4px">
                            <Box width="10px" height="10px" sx={{clipPath:"polygon(0 0, 100% 50%, 0 100%)"}} backgroundColor="#4771DF" position="absolute" ></Box>
                        </Stack>
                        
                        <Box flex={1} height="1px" borderTop="3px dotted #4771DF"/>
                    </Stack>

                    <Stack flexDirection={"row"}  mt={2} gap={1}>

                        <Stack alignItems="center" flexBasis={80}>
                            <Box flex={1} pl={1} pr={1} backgroundColor="#4771DF" color="white" textAlign="center" sx={{writingMode:"vertical-rl", clipPath:"polygon(0 0, 100% 0,100% calc(100% - 15px), 50% 100%, 0 calc(100% - 15px))"}} fontSize={{xs:18,md:20}} fontWeight="bold">月額<Box component="span" sx={{writingMode:"lr"}}>1.5</Box>万円から</Box>
                        </Stack>
                        
                        <Stack flex={1}>
                            <Box textAlign="center" fontSize={{xs:16,md:20}} fontWeight="bold">
                                <Box display="inline-block" backgroundColor="black" width={{xs:15,md:30}} height="1px" sx={{transform:" translateY(-5px) rotate(45deg)"}}/>
                                <Box display="inline-block" >プロにお任せ</Box>
                                <Box display="inline-block" backgroundColor="black" width={{xs:15,md:30}} height="1px" sx={{transform:" translateY(-5px) rotate(-45deg)"}}/>
                            </Box>
                            <Stack mt={1} p={2} backgroundColor="white" borderRadius={2} gap={2}>
                                <Box flex={1} fontSize={18} textAlign="center" fontWeight="bold">
                                    <Box>貴社専属チームが</Box>
                                    <Box>助成金申請サポート</Box>
                                </Box>
                                <Box>
                                    <Link display="block" href={"/flow"+queryString} color="#4771DF" borderColor="#4771DF" backgroundColor="white" target="_blank"  border={3} fontWeight="bold" textAlign="center" p={{xs:"10px 5px",md:"10px 30px"}} fontSize={{xs:16, md:20}} width="100%" borderRadius={100} sx={{
                                    textDecoration:"none",
                                    "&:hover":{
                                        backgroundColor:"#4771DF",
                                        color:"white",
                                    }
                                    }}>サービス内容<Box component="span" margin="0 4px"  fontSize={{xs:11,md:15}}>を</Box>知る</Link>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Box>


            <Box component="section" backgroundColor="#4771DF" pt={10} pb={10}>
                <Box className='section-content' >
                  
                    <Stack flexDirection={{sx:"column",md:"row"}} alignItems="center" backgroundColor="white" gap={{xs:2,md:5}} borderRadius={5} p={{xs:2,md:5}}>
                        
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/price_01.png"} alt="price_01" width="100%" maxWidth={500}></Box>
                        
                        <Stack flex={1} justifyContent="space-evenly" gap={1}>
                            <Box fontSize={{xs:16, md:20}} fontWeight="bold">あれこれできて、助成金のプロがサポートして、</Box>
                            <Box fontSize={{xs:25, md:30}} fontWeight="bold">月額 たったの <Box component="span" color="#ED346C"><Box component="span" fontSize={{xs:30, md:45}}>1.6</Box> 万円</Box>！</Box>

                            <Stack flexDirection="row" sx={before_checkMark_blue2} alignItems="center" textAlign="left" mt={2}><Box fontSize={{xs:16, md:16}} fontWeight="bold" flex={1}>各種労務帳簿を作成、整備、整合</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue2} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:16}} fontWeight="bold" flex={1}>申請書類の作成・届出</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue2} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:16}} fontWeight="bold" flex={1}>貴社専属のプロ集団がサポート</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue2} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:16}} fontWeight="bold" flex={1}>法令遵守 × 独自の経営方針 × 助成金の受給要件などを合わせた目線</Box></Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue2} alignItems="center" textAlign="left"><Box fontSize={{xs:16, md:16}} fontWeight="bold" flex={1}>WEBで進捗管理</Box></Stack>

                            <Box fontSize={{xs:16, md:16}} fontWeight="bold" mt={2}><AccentBox>ここまでできて、月々たったの 1.6万円！</AccentBox></Box>

                            <Box fontSize={{xs:12, md:12}} mt={2}>
                                <Box>※プランにより料金・初期費用等が異なります</Box>
                            </Box>

                        </Stack>
                        
                    </Stack>
                   
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" textAlign="center">
                        <Box>お客様のご状況や経営課題について個別ヒアリングし</Box>
                        <Box>助成金の解説及びコンサルティングいたします。</Box>
                    </Box>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA4.png"} alt="CTA4" width="100%" maxWidth={400} mt={5}></Box>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" color="#ED346C" textAlign="center" mt={5} >まずは、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お気軽に</Box>に、<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お問合せ</Box>ください！</Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" textAlign="center" gap={3} mt={5} >
                        <Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >ご予約<Box component="span" ml={1} mr={1} fontSize={{xs:16, md:20}}>は</Box>こちら<Box component="span" ml={1} fontSize={{xs:16, md:20}}>から</Box></ConversionButtonB>
                        </Box>
                    </Stack>
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pt={10} pb={10}>
                <Box className='section-content' >

                    <Stack justifyContent="center" alignItems="center">
                    <Link href={"/flow"+queryString} backgroundColor="#4771DF" border={3} fontWeight="bold" textAlign="center" p={5} fontSize={{xs:18, md:18}} width={{xs:"100%",md:330    }} borderRadius={2} sx={{
                        color:"white",
                        borderColor:"#4771DF",
                        textDecoration:"none",
                        "&:hover":{
                            backgroundColor:"white",
                            color:"#4771DF",
                        }
                        }}>ご利用までの流れ</Link>
                    </Stack>
                </Box>
            </Box>

            <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">導入効果</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} mt={5} gap={5}>
                        <Stack flexBasis="50%" flex={1} justifyContent="space-around" gap={2}>
                            
                            <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box fontSize={{xs:14, md:16}} fontWeight="bold">安心して採用できるようになり、<br/><AccentBox><Box component="span" fontSize={{xs:20, md:20}}>従業員数</Box>が<Box component="span" fontSize={{xs:20, md:20}}>２倍</Box></AccentBox>に！</Box></Stack>

                            <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box fontSize={{xs:14, md:16}} fontWeight="bold">何役も兼務していた社長の<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:20}}><AccentBox>業務負担が軽減</AccentBox></Box>され、<br/><Box component="span" fontSize={{xs:20, md:20}}><AccentBox>本業に専念</AccentBox></Box>でできるようになった！</Box></Stack>

                            <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box fontSize={{xs:14, md:16}} fontWeight="bold">従業員の<Box component="br" display={{xs:"block",md:"none"}}/><AccentBox fontSize={{xs:20, md:20}}>働きやすい環境</AccentBox>を<Box component="br" display={{xs:"block",md:"none"}}/>作ることができた！</Box></Stack>

                            <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box fontSize={{xs:14, md:16}} fontWeight="bold">従業員のためになる制度などを導入できた上、助成金も受給！<br/>本当の意味で<AccentBox fontSize={{xs:20, md:20}}>会社の成長を支える土台作り</AccentBox>ができた！</Box></Stack>

                            <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box fontSize={{xs:14, md:16}} fontWeight="bold">会社の<AccentBox fontSize={{xs:20, md:20}}>理想とする労働環境を実現</AccentBox>できた！</Box></Stack>
                        
                        </Stack>
                        <Stack flexBasis="50%" backgroundColor="white" borderRadius={2} alignItems="center" pt={2} pb={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_effect1.png"} alt="TOP_effect1" width="100%" maxWidth={500}></Box>
                        </Stack>
                    </Stack>

                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' >
                    <Box textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">導入事例</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                    {customersList.map((item,index) => (
                            <Link display="flex" flex={1} color="inherit" flexBasis="30%" href={`/customers/${item.id}${queryString}`} sx={{textDecoration:"none"}}>
                            <Stack p={2} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={5} >                
                                <Box>
                                    <Box backgroundColor="#4771DF" color="white" fontSize={16} fontWeight="bold" p={1} textAlign="left">{item.accountName}</Box>
                                    <Box component="img" src={process.env.PUBLIC_URL+`/static/media/LP/${item.imageName}.jpg`} alt={item.imageName} width="100%"></Box>
                                </Box>
                                <Box mt={1} >
                                    <Stack component="h3" flexDirection="row" fontSize={11} gap={1}>
                                        <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">業　種</Box>
                                        <Box>{item.industry}</Box>
                                    </Stack>
                                    <Stack flexDirection="row" fontSize={11} mt={1} gap={1}>
                                        <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold">従業員数</Box>
                                        <Box>{item.employee}</Box>
                                    </Stack>
                                </Box>
                                <Box fontSize={22} fontWeight="bold" borderTop="1px dotted gray" borderBottom="1px dotted gray" mt={1} pt={1} pb={1} textAlign="left">{item.title}</Box>
                                <Stack alignItems="center">{styledLink(`/customers/${item.id}${queryString}`)}</Stack>
                            </Stack>
                            </Link>
                        )
                    )}

                    </Stack>

                    <Stack alignItems="center" mt={5}>
                        {styledLink("/customers"+queryString,"導入事例の一覧")}
                    </Stack>

                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#D1EAFE" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    
                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.png"} alt="TOP_award1" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.png"} alt="TOP_award2" width="100%" maxWidth={250}></Box>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.png"} alt="TOP_award3" width="100%" maxWidth={250}></Box>
                    </Stack>
                    <Box fontSize={11} mt={6} textAlign="left">
                        <Box>※1 2024年3月現在</Box>
                        <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                        <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:30, md:40}} mt={10}>
                        <Box>助成金<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>を</Box>フル活用<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>して</Box></Box>
                        <Box>社員が輝く経営サイクル<Box component="span" fontSize={{xs:20, md:25}} ml={1}>をつくる</Box></Box>
                    </Box>

                    <Box fontWeight="bold" fontSize={{xs:35, md:60}} mt={5} mb={5}>「助成金コーディネート」</Box>

                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_J_coordinate.png"} alt="TOP_J_coordinate" width="100%" maxWidth={500}></Box>

                    <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>
                        <Box><AccentBox>多数実績</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>の中で培ってきた</Box><Box component="br" display={{xs:"block",md:"none"}}></Box><AccentBox>ノウハウ・ナレッジ。</AccentBox></Box>
                        <Box><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>労務・助成金の</Box><AccentBox>プロ集団</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>が、継続的に助成金をフル活用できる</Box><AccentBox>しくみづくり</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} >をサポートします。</Box></Box>
                    </Box>
                    
                </Box>
            </Box>

            <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box fontSize={{xs:20, md:30}}><Box component="span" fontSize={{xs:30, md:45}} mr={1}>助成金、</Box>どうせやるなら<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>「フル活用」</Box>！</Box>
                    
                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1_2.png"} alt="CTA1_2" width="100%" maxWidth={400}></Box>

                            <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>で</Box>助成金診断<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                        </Stack>
                        
                        <Stack flex={1} alignItems="center" gap={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2_2.png"} alt="CTA2_2" width="100%" maxWidth={400}></Box>
                            <ConversionButtonB display="block" fontSize={{xs:20, md:30}} >無料<Box component="span" margin="0 4px"  fontSize={{xs:16, md:20}}>で</Box>プロに相談<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>

            <Footer/>
        </>
    )
}

export default Price;